import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/blog/blogs-details.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Luna Journal is for everyone`}</h1>
    <p>{`Almost two years ago, Luna had her first seizure. Many, many seizures later (and dozens of trips to the veterinarian), my fiancé and I came to accept that Luna has canine epilepsy. We’ve been adamant about tracking her health, quality of life, medications, vaccinations and so much more — In fact, that’s why Luna Journal exists. I built Luna Journal to help us collaborate on tracking this data, and to make it easy to pass along any of that data to Luna’s veterinarian or neurologist.`}</p>
    <p>{`There’s been some interesting developments that took place while building Luna Journal and sharing it with others. For one, I am shocked at how many people were interested in using the app for their pets, regardless of health concerns. One thing I’ve learned is that not everyone wants to use Luna Journal the same way. For this reason, I’ve built optional features (like Tags) into the app. Side bar: If you’re not using tags yet and would like to, you can opt in from the settings menu in your app’s drawer.`}</p>
    <p>{`Another takeaway that I’ve found is that people can’t afford the $1.99/month subscription for our cloud storage option. This makes sense, as the people who likely benefit the most from Luna Journal already have several large recurring costs to maintain their pet’s health. This has, unfortunately, deterred people from using Luna Journal. It saddens me to think that there are pets and pet owners who may benefit from better health and general information tracking but aren’t using Luna Journal due to financial concerns. In the past, I’ve offered to offset the cost of cloud storage for anyone who reaches out and asks — operating on a “good faith” policy. This has helped several people, but not everyone has taken me up on this offer and unfortunately, it does not scale.`}</p>
    <p>{`Today I’ve released a new solution to this problem. I’ve launched the 1.1.0 update for Luna Journal which includes full support for a completely free-of-charge experience. This is the default experience that you’ll land in when downloading the app so you’re able to just jump in and start tracking information right away!`}</p>
    <p>{`There are some things to be aware of, however:`}</p>
    <ul>
      <li parentName="ul">{`Data is stored on your device. Not in the cloud.`}</li>
      <li parentName="ul">{`As data is not in the cloud, households are not available and there is no way to collaborate on your pet’s data with others. The free tier works great for a single user wanting to track data and keep their veterinarian in sync.`}</li>
      <li parentName="ul">{`Photos is disabled on the free tier. Photos is a cloud-only feature that allows you to store your pet’s photos in the cloud. Unfortunately, photos can be rather large and this storage is costly. If we can find a way to offset that cost in the future, I will try to make photos free for everyone.`}</li>
    </ul>
    <p>{`This next bit is extremely in the weeds, but its important so please bare with me. Supporting the free tier required almost a complete rewrite of how data is managed in Luna Journal. This introduces a very important new feature for paid users as well. When you are a free tier user, you are locked into the On-Device storage. However, subscribers can choose to use On-Device storage as well. These two storage mechanisms are completely isolated from one another. If you have data in the cloud and you swap to On-Device storage (available via the settings view in the drawer), you will no longer see your cloud data. Don’t worry, its still there and available if you want to swap back.`}</p>
    <p>{`This allows our subscribers to still effectively track data where they may not have access to a stable internet connection. Additionally, it gives you the ability to choose where and how you want to store your data. And if you’re using the free tier and decide to upgrade, cloud storage becomes an option for you, too!`}</p>
    <p>{`It’s important to mention that there is `}<em parentName="p">{`currently`}</em>{` not a way to sync data between storage mechanisms. I am doing research on ways to handle this effectively and will do my best to support this as I can. This is to say that if you store something in the local storage option, the only way to get that data in cloud storage (or vice-versa) is to recreate that data in the other storage option. I have done this several times and know that is it not ideal and will be working on a solution for this soon.`}</p>
    <p>{`Finally, thank you for all the support over the past year. I’ve had feedback from so many of you and it not only helps with building Luna Journal, but reminds me that we’re not alone when it comes to trying to provide the best life possible for our pets.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      